import React, { createContext, useState, useContext } from 'react'
import { Auth } from 'aws-amplify'

export const SANAuthContext = createContext()

export const useAuthContext = () => useContext(SANAuthContext)

export const SANAuthProvider = ({ children }) => {
    const [me, setMe] = useState()
    const [enrollment, setEnrollment] = useState()

    const logout = (history, noCourse) => {
        Auth.signOut().then(() => {
            noCourse ? history.push({
                pathname: '/auth/sem-curso',
                state: { message: noCourse }
            }) : history.push('/')
        })
    }

    const value = {
        me,
        setMe,
        enrollment,
        setEnrollment,
        logout
    }

    return (
        <SANAuthContext.Provider value={value}>
            {children}
        </SANAuthContext.Provider>
    )
}
