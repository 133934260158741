"use strict";

exports.__esModule = true;
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _classnames = _interopRequireDefault(require("classnames"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _fullLogo = _interopRequireDefault(require("../../../assets/images/logo/full-logo.svg"));

var _Icon = _interopRequireDefault(require("../Icon"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var ESSplashLoader = function ESSplashLoader(_ref) {
  var className = _ref.className,
      size = _ref.size,
      image = _ref.image;
  var classes = (0, _classnames.default)('es-splash-loader', className, {
    'es-splash-loader--flexible': size === 'flexible',
    'es-splash-loader--full-height': size === 'fullHeight'
  });
  return _react.default.createElement("div", {
    className: classes
  }, _react.default.createElement("img", {
    src: image,
    alt: "E-Sanar logo"
  }), _react.default.createElement(_Icon.default, {
    type: "loading"
  }));
};

ESSplashLoader.propTypes = {
  className: _propTypes.default.string,
  image: _propTypes.default.oneOfType([_propTypes.default.string, _propTypes.default.node]),
  size: _propTypes.default.oneOf(['flexible', 'fullHeight'])
};
ESSplashLoader.defaultProps = {
  image: _fullLogo.default,
  size: 'fullHeight'
};
var _default = ESSplashLoader;
exports.default = _default;