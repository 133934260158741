"use strict";

exports.__esModule = true;
exports.default = void 0;
var _default = {
  mainMenu: {
    leftOff: 'Continue where I left off',
    rankingPoints: 'points'
  }
};
exports.default = _default;