"use strict";

exports.__esModule = true;
exports.default = void 0;
var CustomLocalStorage = {
  setItem: function setItem(key, value) {
    localStorage.setItem(key, value);
    return value;
  },
  getItem: function getItem(key) {
    return localStorage.getItem(key);
  },
  removeItem: function removeItem(key) {
    localStorage.removeItem(key);
  },
  clear: function clear() {
    localStorage.clear();
  }
};
var _default = CustomLocalStorage;
exports.default = _default;