"use strict";

exports.__esModule = true;
exports.default = void 0;

var _awsAmplify = require("aws-amplify");

var _i18n = _interopRequireDefault(require("../../Config/i18n"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var sendPasswordReset = function sendPasswordReset(password, email, code) {
  return _awsAmplify.Auth.forgotPasswordSubmit(email, code, password).then(function (res) {
    return Promise.resolve({
      message: _i18n.default.t('sanarui:authMessages.passwordWasReseted')
    });
  }).catch(function (err) {
    switch (err.code) {
      case 'ExpiredCodeException':
        return Promise.reject({
          code: 'ExpiredCodeException',
          message: _i18n.default.t('sanarui:authMessages.expiredCodeException')
        });

      case 'CodeMismatchException':
        return Promise.reject({
          code: 'CodeMismatchException',
          message: _i18n.default.t('sanarui:authMessages.codeMismatchException')
        });

      case 'LimitExceededException':
        return Promise.reject({
          code: 'LimitExceededException',
          message: _i18n.default.t('sanarui:authMessages.limitExceededException')
        });

      default:
        return Promise.reject({
          code: 'Generic',
          message: _i18n.default.t('sanarui:authMessages.generic')
        });
    }
  });
};

var _default = sendPasswordReset;
exports.default = _default;