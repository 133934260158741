"use strict";

exports.__esModule = true;
exports.default = void 0;

var _i18next = _interopRequireDefault(require("i18next"));

var _i18nextBrowserLanguagedetector = _interopRequireDefault(require("i18next-browser-languagedetector"));

var _reactI18next = require("react-i18next");

var _pt = _interopRequireDefault(require("./locales/sanarui/pt"));

var _en = _interopRequireDefault(require("./locales/sanarui/en"));

var _pt2 = _interopRequireDefault(require("./locales/esanar/pt"));

var _en2 = _interopRequireDefault(require("./locales/esanar/en"));

var _pt3 = _interopRequireDefault(require("./locales/sanarflix/pt"));

var _en3 = _interopRequireDefault(require("./locales/sanarflix/en"));

var _pt4 = _interopRequireDefault(require("./locales/resmed/pt"));

var _en4 = _interopRequireDefault(require("./locales/resmed/en"));

var _pt5 = _interopRequireDefault(require("./locales/components/pt"));

var _en5 = _interopRequireDefault(require("./locales/components/en"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var sanaruiI18n = _i18next.default.createInstance();

sanaruiI18n.use(_i18nextBrowserLanguagedetector.default).use(_reactI18next.initReactI18next).init({
  lng: 'pt',
  fallbackLng: 'en',
  debug: true,
  resources: {
    pt: {
      sanarui: _pt.default,
      esanar: _pt2.default,
      sanarflix: _pt3.default,
      resmed: _pt4.default,
      components: _pt5.default
    },
    en: {
      sanarui: _en.default,
      esanar: _en2.default,
      sanarflix: _en3.default,
      resmed: _en4.default,
      components: _en5.default
    }
  },
  interpolation: {
    escapeValue: false
  }
});
var _default = sanaruiI18n;
exports.default = _default;