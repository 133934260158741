"use strict";

exports.__esModule = true;
exports.default = void 0;
var _default = {
  global: {
    percentOf: '{{done}} of {{final}}',
    you: 'You',
    back: 'Back',
    leave: 'Leave',
    loadMore: 'Load more'
  },
  logout: {
    message: 'Are you sure you want to sign out now?'
  }
};
exports.default = _default;