"use strict";

exports.__esModule = true;
exports.default = void 0;

var _customSessionStorage = _interopRequireDefault(require("./customSessionStorage"));

var _customLocalStorage = _interopRequireDefault(require("./customLocalStorage"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var esConfigureAuthStorage = function esConfigureAuthStorage() {
  var item = JSON.parse(localStorage.getItem('es-keep-me-logged-in'));
  var storage;

  if (!item) {
    storage = _customSessionStorage.default;
  } else {
    storage = _customLocalStorage.default;
  }

  return storage;
};

var _default = esConfigureAuthStorage;
exports.default = _default;