"use strict";

exports.__esModule = true;
exports.default = void 0;

var _awsAmplify = require("aws-amplify");

var _i18n = _interopRequireDefault(require("../../Config/i18n"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var esSendPasswordRecovery = function esSendPasswordRecovery(email) {
  return _awsAmplify.Auth.forgotPassword(email).catch(function (error) {
    switch (error.code) {
      case 'LimitExceededException':
        return Promise.reject({
          code: error.code,
          message: _i18n.default.t('sanarui:authMessages.limitExceededException')
        });

      case 'UserNotFoundException':
        return Promise.reject({
          code: error.code,
          message: _i18n.default.t('sanarui:authMessages.userNotFoundException')
        });

      case 'InvalidParameterException':
        return Promise.reject({
          code: error.code,
          message: _i18n.default.t('sanarui:authMessages.invalidParameterException')
        });

      default:
        return Promise.reject({
          code: error.code,
          message: _i18n.default.t('sanarui:authMessages.generic')
        });
    }
  });
};

var _default = esSendPasswordRecovery;
exports.default = _default;