"use strict";

exports.__esModule = true;
exports.default = void 0;
var _default = {
  global: {
    termsOfUse: 'Termos de Uso',
    privacyPolicy: 'Política de Privacidade',
    send: 'Enviar',
    copyright: 'Todos os direitos reservados',
    about: 'Sobre o SanarFlix',
    backStart: 'Voltar ao início',
    leave: 'Sair',
    back: 'Voltar',
    yes: 'Sim',
    no: 'Não',
    expert: 'Especialista'
  },
  auth: {
    accessAccount: 'Acessar conta',
    hello: 'Olá, futuro residente!',
    signInDescription: 'Seja bem-vindo(a) à plataforma de estudos da Sanar Residência Médica. Aqui você terá acesso à todos os conteúdos e informações para se preparar para a prova de Residência. Bons estudos!',
    keepMeLoggedIn: 'Manter logado',
    forgotPassword: 'Esqueci minha senha',
    login: 'Entrar',
    title: 'Ou entre com os dados abaixo',
    newPassword: 'Nova senha',
    currentPassword: 'Senha atual',
    messageChangeSuccess: 'Senha alterada com sucesso!',
    marketing: {
      title: 'Tenha uma experiência de excelência nos seus estudos e na sua carreira',
      description: 'Acompanhe seu desempenho, tenha acesso a questões, conteúdos exclusivos e diversos conteúdos que vão te ajudar a conseguir alcançar seus objetivos',
      action: 'Conheça nosso cursos'
    },
    sendPasswordRecovery: {
      title: 'Esqueci minha senha',
      subtitle: 'Preencha o campo abaixo e nós te enviaremos um link de recuperação alteração de senha',
      email: 'Digite seu e-mail cadastrado'
    },
    sendResetPassword: {
      title: 'Troque sua senha',
      subtitle: 'Cadastre uma nova senha preenchendo os campos abaixo:',
      confirmPassword: 'Confirme sua senha',
      success: 'Senha redefinida com sucesso!'
    },
    createNewPassword: {
      title: 'Crie uma nova senha',
      button: 'Criar senha'
    },
    passwordResetSent: {
      title: 'Enviado com sucesso!',
      subtitle: 'Um link de recuperação foi enviado para:',
      advice: 'Fique atento a caixa de spam caso não encontre o e-mail de recuperação.'
    },
    validations: {
      passwordsMismatch: 'As senhas não conferem.',
      minPassword: 'Senha deve ter no mínimo {{min}} caracteres.'
    },
    footer: {
      onEnter: 'Ao entrar na plataforma, você concorda com nossos',
      us: 'e nossa'
    },
    newPasswordChallenge: {
      title: 'Crie sua senha',
      subtitle: 'Agora é pra valer! cadastre uma nova senha preenchendo os campos abaixo:',
      submit: 'Criar',
      success: 'Senha criada com sucesso!'
    }
  },
  authMessages: {
    hasNoSubscription: 'Ops! Você não possui nenhum curso ativo',
    generic: 'Ocorreu um erro. Tente novamente mais tarde.',
    notAuthorizedException: 'Desculpe, essa combinação inserida de e-mail e senha está incorreta. Verifique seus dados e tente novamente!',
    limitExceededException: 'Você excedeu o limite de tentativas. Tente novamente mais tarde.',
    userNotFoundException: 'Desculpe, não encontramos nenhuma conta associada ao e-mail inserido. Por favor tente novamente.',
    invalidParameterException: 'Você ainda não possui um e-mail ou telefone confirmado para esta conta.',
    expiredCodeException: 'O link de redefinição da senha expirou. Solicite um novo.',
    codeMismatchException: 'O link de redefinição da senha é inválido. Solicite um novo.',
    passwordWasReseted: 'Senha alterada com sucesso!',
    noEnrollment: 'Não há matrículas para o usuário informado.',
    forgot: {
      notAuthorizedException: 'Desculpe, não é possível recuperar sua senha. Verifique seu email e logue com sua senha temporária! ou entre em contato com o nosso suporte.',
      limitExceededException: 'Você excedeu o limite de tentativas. Tente novamente mais tarde.',
      generic: 'Ocorreu um erro. Tente novamente mais tarde.',
      expiredCodeException: 'O link de redefinição da senha expirou. Solicite um novo.',
      codeMismatchException: 'O link de redefinição da senha é inválido. Solicite um novo.',
      userNotFoundException: 'Desculpe, não encontramos nenhuma conta associada ao e-mail inserido. Por favor tente novamente.'
    }
  },
  mainMenu: {
    back: 'Voltar ao menu principal',
    initial: {
      title: 'Menu',
      begin: 'Início',
      practiceArea: 'Área de Prática',
      performance: 'Desempenho',
      lives: 'Lives',
      simulated: 'Simulado',
      schedule: 'Cronograma',
      account: 'Minha conta',
      changeCourse: 'Trocar de curso'
    },
    account: {
      title: 'Minha Conta',
      management: 'Gerenciamento',
      myData: 'Meus dados',
      changePassword: 'Trocar minha senha',
      complementaryRegister: 'Ficha complementar',
      help: 'Ajuda',
      helpCenter: 'Central de ajuda',
      otherLinks: 'Outros links',
      termsOfUse: 'Termos de Uso',
      privacyPolicy: 'Política de Privacidade',
      signOut: 'Sair da conta'
    },
    classroom: {
      specialty: 'Nome da especialidade'
    },
    changeCourse: {
      title: 'Estou estudando',
      subtitle: 'Trocar de curso',
      suggestedClass: 'Aula sugerida',
      errorGoClass: 'Ocorreu um erro ao tentar acessar essa aula. Tente novamente mais tarde.'
    }
  },
  logout: {
    message: 'Tem certeza que deseja sair da conta agora?',
    signOut: 'Sair da Conta'
  },
  home: {
    header: {
      completeness: 'Completude do curso'
    },
    general: {
      title: 'Visão Geral',
      subheader: {
        title: 'Especialidades',
        subtitle: 'Escolha qual especialidade quer estudar hoje'
      },
      card: {
        title: 'App Residência Médica',
        subtitle: 'Baixe o App com mais de 100.000 questões de provas para continuar treinando após o estudo.'
      }
    },
    about: {
      title: 'Sobre o Curso',
      texts: {
        title: 'Olá. Bem-vindo ao Residência Médica!',
        subtitle: 'Que legal ter você conosco',
        paragraph1: 'Essa aqui é a nossa plataforma de estudos. Aliás, nossa não, sua! Ela foi feita especialmente para os nossos alunos.',
        paragraph2: 'Fizemos este passo a passo de como funciona o nosso curso, e por isso é muito importante que você leia até o final. É fundamental que você leia até o final. Sério, leia até o final!'
      }
    }
  },
  subspecialties: {
    header: {
      completeness: 'Completude'
    },
    subheader: {
      title: 'Áreas de Interesse'
    },
    errorLoadLessons: 'Ocorreu um erro ao carregar suas aulas'
  },
  modalThemes: {
    continue: 'Continuar de onde parei',
    errorGoClass: 'Ocorreu um erro ao tentar acessar essa aula. Tente novamente mais tarde.'
  },
  main: {
    errorLoadActiveCourse: 'Ocorreu um erro ao carregar seu curso ativo'
  },
  changePassword: {
    feedback: {
      success: 'A sua senha foi atualizada com sucesso!'
    }
  },
  practicalArea: {
    filter: {
      header: {
        title: 'Área de Prática',
        subtitle: 'Hora de praticar tudo o que você aprendeu no curso',
        start: 'Iniciar prática'
      },
      subheader: {
        title: 'Hora de praticar',
        subtitle: 'Para começar, escolha os filtros que deseja abaixo'
      },
      selecteds: {
        category: {
          selecteds: 'Categorias',
          choose: 'Escolher categorias',
          title: 'Categoria de Prova'
        },
        specialty: {
          selecteds: 'Especialidades',
          choose: 'Escolher especialidades',
          title: 'Especialidade'
        },
        subspecialty: {
          selecteds: 'Subespecialidades',
          choose: 'Escolher subespecialidades',
          title: 'Subespecialidade'
        },
        theme: {
          selecteds: 'Temas',
          choose: 'Escolher temas',
          title: 'Tema'
        }
      },
      advanced: {
        title: {
          open: 'Ocultar filtros avançados',
          close: 'Ver filtros avançados'
        },
        institution: {
          title: 'Instituição',
          placeholder: 'Escolher a instituição de ensino'
        },
        year: {
          title: 'Ano',
          placeholder: 'Escolher o ano. (Min: {{min}}, máx: {{max}})'
        },
        state: {
          title: 'Estado',
          placeholder: 'Escolher o estado'
        },
        onlyHasImages: 'Com imagem',
        onlyComments: 'Apenas comentadas'
      }
    },
    finished: {
      title: 'Resultado',
      subtitle: 'Verifique como você se saiu nessa sessão de prática',
      button: 'Área de prática'
    },
    question: {
      filters: 'Filtros',
      seeFilters: 'Ver filtros',
      corrects: 'Corretas',
      wrong: 'Erradas',
      skipped: 'Puladas',
      continue: 'Continuar',
      failReplyQuestion: 'Ocorreu um erro ao responder questão.',
      failLoadQuestions: 'Ocorreu um erro ao carregar questões.',
      empty: 'Ops, não foram encontradas questões com os filtros atuais. Clique em "Ver Filtros" e tente novamente utilizando novos',
      endPractice: {
        button: 'Encerrar prática',
        modal: {
          title: 'Ops! Nenhuma questão foi respondida.',
          subtitle: 'Que tal aprimorar seus conhecimentos reiniciando a prática?',
          close: 'Encerrar',
          restart: 'Reiniciar prática'
        }
      },
      exit: {
        title: 'Ops! Você está saindo da prática.',
        subtitle: 'Deseja realmente encerrar a prática?'
      },
      title: 'Questão'
    }
  },
  termsAndPrivacy: {
    termAccept: 'Li e concordo com os Termos de Uso',
    privacyAccept: 'Li e concordo com as Políticas de privacidade',
    button: 'avançar'
  },
  classroom: {
    quiz: {
      question: 'Questão',
      questionMap: 'Mapa de questões'
    },
    collection: {
      error: 'Ocorreu um erro ao carregar listagem de vídeos'
    }
  },
  schedule: {
    at: 'às',
    until: 'até',
    suggestedClass: 'Aula sugerida',
    header: {
      title: 'Cronograma',
      subtitle: 'Use esse espaço para organizar os seus estudos'
    },
    changeEvent: {
      error: 'Ocorreu um erro ao mover aula - {{name}}',
      exceeded: 'Você excedeu o limite de aulas por dia'
    },
    suggestion: 'Habilitar Edição',
    pdfDownload: 'Baixar em .pdf',
    today: 'Hoje',
    thisWeek: {
      title: 'Esta semana',
      subtitle: 'Confira as suas próximas atividades'
    },
    emptyToday: 'Você não tem nenhuma aula para hoje',
    emptyWeek: 'Você não tem nenhuma aula nessa semana',
    switchError: 'Ocorreu um erro ao alternar cronograma',
    loadingError: 'Ocorreu um erro ao carregar cronograma',
    loadingWeek: 'Ocorreu um erro ao carregar eventos da semana',
    loadingToday: 'Ocorreu um erro ao carregar eventos de hoje',
    pdfDownloadFail: 'Ocorreu um erro ao fazer o download do PDF',
    modal: {
      day: 'Dia',
      lesson: {
        title: 'Aula',
        watch: 'Assistir aula',
        watched: 'Aula assistida'
      },
      live: {
        title: 'Live',
        button: 'Assistir live',
        comingSoon: 'Em breve'
      },
      suggestion: {
        title: 'Habilitar Edição',
        descriptionDisable: 'Ao desabilitar essa opção, você perderá todas as alterações feitas no seu calendário.',
        descriptionEnable: 'Ao habilitar essa opção, você poderá editar seu calendário movendo as aulas.',
        confirm: 'Confirmar',
        back: 'Voltar'
      }
    },
    errorGoClass: 'Ocorreu um erro ao tentar acessar essa aula. Tente novamente mais tarde.',
    monthAbbr: {
      0: 'Jan',
      1: 'Fev',
      2: 'Mar',
      3: 'Abr',
      4: 'Mai',
      5: 'Jun',
      6: 'Jul',
      7: 'Ago',
      8: 'Set',
      9: 'Out',
      10: 'Nov',
      11: 'Dez'
    },
    subtitle: {
      completed: 'Atividades Incompletas',
      uncompleted: 'Atividade Completas',
      complementary: 'Atividades Complementares'
    }
  },
  lives: {
    title: 'Lives',
    subtitle: 'Veja as lives que ainda vão acontecer e as que já rolaram',
    empty: 'Não existe nenhuma live',
    facilitedBy: 'Live facilitada por:',
    nextsList: {
      title: 'Próximas lives',
      at: 'às'
    },
    previousList: {
      title: 'Lives anteriores',
      loadMore: 'Carregar mais'
    },
    previous: {
      title: 'Assistir live anterior',
      back: 'Voltar para lives'
    },
    chat: 'Chat',
    liveChat: 'Chat ao vivo',
    sendMessageError: 'Ocorreu um erro ao enviar sua mensagem'
  },
  userProfile: {
    title: 'Cadastro complementar',
    modalSubtitle: 'Preencha a ficha abaixo para continuar',
    modalSubmit: 'ENVIAR',
    pageSubtitle: 'Confirme os dados abaixo para continuar',
    pageSubmit: 'SALVAR',
    pagePresentation: 'Olá futuro residente! Conta pra gente um pouco mais sobre você para traçarmos juntos uma trilha pra sua aprovação :)',
    inputPlaceholder: 'Selecione',
    institutions: 'Quais instituições quer fazer prova?',
    specialties: 'Quais especialidades quer seguir?',
    testExperiences: {
      label: 'Qual sua experiência com provas?',
      none: 'Nunca fiz',
      one: 'Já fiz uma vez',
      many: 'Já fiz mais de uma vez'
    },
    objective: {
      label: 'Qual o seu principal objetivo de estudo?',
      college: 'Faculdade',
      residence: 'Residência Médica',
      revalidate: 'Revalida'
    },
    preparatoryCourse: {
      label: 'Fez/Faz algum outro curso preparatório?',
      no: 'Não',
      yes: 'Sim'
    },
    previousResidencyCourse: {
      label: 'Qual curso preparatório?'
    },
    graduatedStep: {
      label: 'Quando você entrou na faculdade?',
      year: 'Ano',
      semester: 'Semestre'
    },
    medUniversity: 'Qual faculdade você cursa/cursou?',
    placeholder: 'Selecione',
    mutations: {
      success: 'Sucesso, seus dados foram salvos corretamente!',
      error: 'Erro, ocorreu um erro e seus dados não foram salvos!'
    },
    loadError: 'Erro, não foi possivel carregar os dados'
  },
  modalInactivePacks: {
    message: 'Ops, seu curso só será liberado em {{date}}. Guarda a ansiedade, tá?',
    action: 'Retornar a tela de login'
  }
};
exports.default = _default;