"use strict";

exports.__esModule = true;
exports.default = exports.esFacebookCreateScript = void 0;

var _awsAmplify = require("aws-amplify");

var esFacebookSignIn = function esFacebookSignIn() {
  return doLoginIntoCognito();
};

var esFacebookCreateScript = function esFacebookCreateScript(key) {
  window.fbAsyncInit = function () {
    return fbAsyncInit(key);
  };

  var script = document.createElement('script');
  script.src = 'https://connect.facebook.net/en_US/sdk.js';
  script.async = true;
  script.onload = initFB;
  document.body.appendChild(script);
};

exports.esFacebookCreateScript = esFacebookCreateScript;

var getLoginStatus = function getLoginStatus() {
  var fb = window.FB;
  return new Promise(function (resolve, reject) {
    fb.getLoginStatus(function (response) {
      resolve(response);
    });
  });
};

var getFacebookUserData = function getFacebookUserData(response) {
  return new Promise(function (resolve) {
    var _response$authRespons = response.authResponse,
        accessToken = _response$authRespons.accessToken,
        expiresIn = _response$authRespons.expiresIn;
    var date = new Date();
    var expires_at = expiresIn * 1000 + date.getTime();

    if (!accessToken) {
      return;
    }

    var fb = window.FB;
    fb.api('/me', {
      fields: 'name,email'
    }, function (response) {
      var user = {
        name: response.name,
        email: response.email
      };
      resolve({
        user: user,
        accessToken: accessToken,
        expires_at: expires_at
      });
    });
  });
};

var requestFacebookLogin = function requestFacebookLogin() {
  var fb = window.FB;
  return new Promise(function (resolve) {
    fb.login(resolve);
  });
};

var doLoginIntoCognito = function doLoginIntoCognito() {
  return _awsAmplify.Auth.federatedSignIn({
    provider: 'Facebook'
  }).catch(function (err) {
    return console.log(err);
  });
};

var fbAsyncInit = function fbAsyncInit(key) {
  var fb = window.FB;
  fb.init({
    appId: key,
    cookie: true,
    xfbml: true,
    version: 'v2.11'
  });
};

var initFB = function initFB() {
  var fb = window.FB;
};

var _default = esFacebookSignIn;
exports.default = _default;