"use strict";

exports.__esModule = true;
exports.default = void 0;

var _awsAmplify = require("aws-amplify");

var _i18n = _interopRequireDefault(require("../../Config/i18n"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var esSignIn = function esSignIn(email, password) {
  return _awsAmplify.Auth.signIn(email, password).catch(function (err) {
    switch (err.code) {
      case 'UserNotFoundException':
        return Promise.reject({
          code: err.code,
          message: _i18n.default.t('sanarui:authMessages.userNotFoundException')
        });

      case 'NotAuthorizedException':
        return Promise.reject({
          code: err.code,
          message: _i18n.default.t('sanarui:authMessages.notAuthorizedException')
        });

      case 'UserLambdaValidationException':
        return Promise.reject({
          code: err.code,
          message: _i18n.default.t('sanarui:authMessages.noEnrollment')
        });

      default:
        return Promise.reject({
          code: err.code,
          message: _i18n.default.t('sanarui:authMessages.generic')
        });
    }
  });
};

var _default = esSignIn;
exports.default = _default;