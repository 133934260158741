"use strict";

var _defineProperty2 = require("/vercel/path0/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/defineProperty");

exports.__esModule = true;
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _button = _interopRequireDefault(require("antd/lib/button"));

var _classnames = _interopRequireDefault(require("classnames"));

var _Icon = _interopRequireDefault(require("../Icon"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    if (enumerableOnly) symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    });
    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

var ESButton = function ESButton(_ref) {
  var _ref2;

  var className = _ref.className,
      clear = _ref.clear,
      size = _ref.size,
      variant = _ref.variant,
      color = _ref.color,
      block = _ref.block,
      blockOnlyMobile = _ref.blockOnlyMobile,
      children = _ref.children,
      bold = _ref.bold,
      uppercase = _ref.uppercase,
      href = _ref.href,
      icon = _ref.icon,
      circle = _ref.circle,
      loading = _ref.loading,
      htmlType = _ref.htmlType,
      disabled = _ref.disabled,
      props = _objectWithoutProperties(_ref, ["className", "clear", "size", "variant", "color", "block", "blockOnlyMobile", "children", "bold", "uppercase", "href", "icon", "circle", "loading", "htmlType", "disabled"]);

  var classes = (0, _classnames.default)('es-button', className, (_ref2 = {}, _defineProperty2(_ref2, "es-button__".concat(size), size), _defineProperty2(_ref2, "es-button__variant--".concat(variant), variant), _defineProperty2(_ref2, "".concat(color), color), _defineProperty2(_ref2, 'es-button__block', block), _defineProperty2(_ref2, 'es-button__block--mobile', blockOnlyMobile), _defineProperty2(_ref2, "loading", loading), _defineProperty2(_ref2, "bold", bold), _defineProperty2(_ref2, "uppercase", uppercase), _defineProperty2(_ref2, "circle", circle), _ref2), 'ant-btn');

  var mapChildren = function mapChildren(child) {
    if (typeof child === 'string' || typeof child === 'number') {
      return _react.default.createElement("span", null, child);
    }

    return child;
  };

  var kids = children && children.length > 1 ? _react.default.Children.map(children, mapChildren) : children;

  if (!!href) {
    return _react.default.createElement("a", _extends({
      href: href,
      "data-testid": "es-button",
      className: classes
    }, props), icon && _react.default.createElement(_Icon.default, {
      type: icon
    }), kids);
  }

  var Loading = function Loading() {
    return _react.default.createElement(_Icon.default, {
      type: "loading"
    });
  };

  return _react.default.createElement("button", _extends({
    disabled: disabled || loading,
    type: htmlType,
    className: classes,
    "data-testid": "es-button"
  }, props), icon && _react.default.createElement(_Icon.default, {
    type: icon
  }), loading && _react.default.createElement(Loading, null), kids);
};

ESButton.propTypes = Object.assign(_objectSpread({}, _button.default['propTypes']), {
  className: _propTypes.default.oneOfType([_propTypes.default.string, _propTypes.default.bool]),
  clear: _propTypes.default.bool,
  href: _propTypes.default.string,
  fontSize: _propTypes.default.oneOfType([_propTypes.default.number, _propTypes.default.string]),
  target: _propTypes.default.string,
  size: _propTypes.default.oneOf(['xsmall', 'small', 'medium', 'large']),
  variant: _propTypes.default.oneOf(['solid', 'outlined', 'text']),
  color: _propTypes.default.oneOf(['primary', 'secondary', 'white', 'black', 'default', 'light', 'grey']),
  bold: _propTypes.default.bool,
  blockOnlyMobile: _propTypes.default.bool
});
ESButton.defaultProps = {
  size: 'medium',
  htmlType: 'button'
};
var _default = ESButton;
exports.default = _default;