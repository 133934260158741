"use strict";

var _defineProperty2 = require("/vercel/path0/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/defineProperty");

exports.__esModule = true;
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _icon = _interopRequireDefault(require("antd/lib/icon"));

var _classnames = _interopRequireDefault(require("classnames"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    if (enumerableOnly) symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    });
    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

var ESIcon = function ESIcon(_ref) {
  var className = _ref.className,
      fontSize = _ref.fontSize,
      style = _ref.style,
      color = _ref.color,
      props = _objectWithoutProperties(_ref, ["className", "fontSize", "style", "color"]);

  var classes = (0, _classnames.default)('es-icon', className, _defineProperty2({}, "text-".concat(color), color));
  return _react.default.createElement(_icon.default, _extends({
    className: classes
  }, props, {
    style: _objectSpread({}, style, {
      fontSize: fontSize
    })
  }));
};

ESIcon.propTypes = Object.assign(_objectSpread({}, _icon.default['propTypes']), {
  className: _propTypes.default.string,
  style: _propTypes.default.any,
  fontSize: _propTypes.default.oneOfType([_propTypes.default.string, _propTypes.default.number]),
  theme: _propTypes.default.oneOf(['filled', 'outlined', 'twoTone']),
  spin: _propTypes.default.bool,
  rotate: _propTypes.default.number,
  component: _propTypes.default.func,
  twoToneColor: _propTypes.default.string
});
ESIcon.defaultProps = _icon.default['defaultProps'];
var _default = ESIcon;
exports.default = _default;