"use strict";

exports.__esModule = true;
exports.default = void 0;
var _default = {
  global: {
    percentOf: '{{done}} de {{final}}',
    you: 'Você',
    back: 'Voltar',
    leave: 'Sair',
    loadMore: 'Carregar mais',
    send: 'Enviar',
    continue: 'Continuar',
    subject: 'Disciplina',
    activity: 'Aula',
    confirm: 'Confirmar',
    yes: 'Sim',
    no: 'Não',
    failLoadLastAccessed: 'Erro ao carregar "Continuar onde parei".',
    termsOfUse: 'Termos de Uso',
    privacyPolicy: 'Política de Privacidade',
    remove: 'Remover'
  },
  auth: {
    accessAccount: 'Acessar conta',
    signInDescription: 'Área do Aluno',
    keepMeLoggedIn: 'Manter-me logado',
    forgotPassword: 'Esqueci minha senha',
    login: 'Entrar',
    title: 'Ou entre com os dados abaixo',
    newPassword: 'Nova senha',
    SSODescription: 'Esse login e senha é o seu acesso oficial da Sanar! Você pode utilizar essas mesmas informações de acesso em todas as plataformas da Sanar.',
    currentPassword: 'Senha atual',
    messageChangeSuccess: 'Senha alterada com sucesso!',
    marketing: {
      title: 'Tenha uma experiência de excelência nos seus estudos e na sua carreira',
      description: 'Acompanhe seu desempenho, tenha acesso a questões, conteúdos exclusivos e diversos conteúdos que vão te ajudar a conseguir alcançar seus objetivos',
      linkDescription: 'CONHEÇA NOSSOS CURSOS'
    },
    sendPasswordRecovery: {
      title: 'Esqueci minha senha',
      subtitle: 'Preencha o campo abaixo e nós te enviaremos um link de recuperação de senha.',
      email: 'Digite seu e-mail cadastrado'
    },
    sendResetPassword: {
      title: 'Troque sua senha',
      subtitle: 'Cadastre uma nova senha preenchendo os campos abaixo:',
      confirmPassword: 'Confirme sua senha'
    },
    createNewPassword: {
      title: 'Crie uma nova senha',
      button: 'Criar senha'
    },
    passwordResetSent: {
      title: 'Enviado com sucesso!',
      subtitle: 'Um link de recuperação foi enviado para:',
      advice: 'Fique atento a caixa de spam caso não encontre o e-mail de recuperação.'
    },
    passwordResetSuccess: {
      title: 'Senha alterada com sucesso!',
      subtitle: 'Escolha qual plataforma deseja acesssar:'
    },
    validations: {
      passwordsMismatch: 'As senhas não conferem.',
      minPassword: 'Senha deve ter no mínimo {{min}} caracteres.'
    },
    CTACourse: {
      dontHaveAccount: 'Ainda não tem conta?',
      meet: 'Conheça nossos cursos'
    },
    footer: {
      onEnter: 'Ao entrar na plataforma, você concorda com nossos',
      us: 'e nossa'
    }
  },
  logout: {
    message: 'Tem certeza que deseja sair da conta agora?'
  },
  mainMenu: {
    back: 'Voltar ao menu principal',
    continueCourse: 'Continuar na Disciplina {{module}}, aula {{class}}',
    title: {
      menu: 'Menu',
      notifications: 'Notificações',
      studying: 'Estou estudando',
      myAccount: 'Minha conta',
      search: 'Busca'
    },
    initial: {
      init: 'Início',
      notifications: 'Notificações',
      schedule: 'Cronograma',
      saved: 'Favoritos',
      performace: 'Desempenho',
      questions: 'Banco de questões',
      changeCourse: 'Trocar de curso',
      myAccount: 'Minha conta'
    },
    myAccount: {
      management: 'Gerenciamento',
      myData: 'Meus dados',
      changePassword: 'Trocar minha senha',
      help: 'Ajuda',
      sendFeedback: 'Enviar feedback',
      helpCenter: 'Central de ajuda',
      otherLinks: 'Outros links',
      leaveAccount: 'Sair da conta'
    },
    changeCourse: {
      changeCourse: 'Trocar de curso',
      failChange: 'Ocorreu um erro ao trocar de curso.',
      failChangeExpired: 'Opa! Este curso está expirado!',
      failChangeCanceled: 'Opa! Este curso está cancelado!',
      message: {
        keyWithCount: 'Você tem <strong>{{count}}</strong> outro curso',
        keyWithCount_plural: 'Você tem <strong>{{count}}</strong> outros cursos'
      },
      placeholder: 'Outros cursos'
    },
    notifications: {
      notRead: 'Não lidas',
      read: 'Já lidas',
      markAllRead: 'Marcar todas como lidas',
      alreadyRead: 'Já lidos',
      excludeRlreadyRead: 'Excluir já lidos',
      markRead: 'Marcar como lida',
      markUnread: 'Marcar como não lida'
    }
  },
  courseDetails: {
    viewMyPerformance: 'Ver meu desempenho',
    myDevelopment: 'Meu desenvolvimento',
    myDevelopmentSubtitle: 'Vejas as indicações de como você está se saindo no seu aprendizado com seu curso',
    cardCommitmentTitle: 'NÍVEL DE COMPROMETIMENTO',
    cardCommitmentDoubt: 'Mede seu comprometimento em relação aos outros alunos',
    cardCommitmentDescription: 'melhor do que outros estudantes',
    cardCommitmentLow: 'Baixo',
    cardCommitmentMid: 'Médio',
    cardCommitmentNice: 'Incrível',
    cardConsistencyTitle: 'Consistência',
    cardConsistencyDoubt: 'Indica se você está estudando, pela plataforma, a mesma quantidade de horas semanais informada no início do curso',
    cardConsistencyDescription: 'Horas estudadas na semana',
    cardProgressTitle: 'Progresso',
    cardProgressDoubt: 'Indica a quantidade de aulas assistidas em relação às aulas que você já deveria ter assistido nessa altura do curso',
    cardProgressDescription: 'Aulas assistidas no total',
    cardPracticeTitle: 'PRÁTICA REGULAR',
    cardPracticeDoubt: 'Indica a quantidade de questões respondidas em relação às questões de aula que você já deveria ter respondido nessa altura do curso',
    cardPracticeDescription: 'Atividades respondidas',
    cardInteractionTitle: 'INTERAÇÃO',
    cardInteractionDoubt: 'Indica o número de interações (pergunta, resposta ou comentário) que você fez na última semana. Interações auxiliam na construção de significados aos conceitos, melhorando a fixação do assunto',
    cardInteractionDescription: 'Perguntas e repostas realizadas',
    recentCommentsTitle: 'Perguntas e Respostas Recentes',
    recentCommentsSubtitle: 'Vejas as últimas perguntas feitas por seus colegas de curso',
    recentCommentsDays: 'Há {{days}} dias',
    recentCommentsButton: 'Ver todas as perguntas',
    recentCommentsOneComment: 'Participe da comunidade do curso!',
    recentCommentsOneCommentAction: 'PERGUNTAR',
    recentCommentsNoComments: 'Seja o primeiro a fazer uma pergunta!',
    recentCommentsNoCommentsAction: 'QUERO PERGUNTAR',
    recentlySavedTitle: 'Favoritos',
    recentlySavedSubtitle: 'Veja os dois últimos itens favoritados',
    recentlySavedButton: 'Ver todos os favoritos',
    recentlySavedOneItem: 'Continue salvando mais itens para a sua coleção de conteúdos!',
    recentlySavedNoItems: 'Comece a salvar seus conteúdos favoritos!',
    recentlySavedNoItemsAction: 'ADICIONAR EXEMPLO',
    recentlySavedContentIndexes: 'Módulo {{module}}, aula {{class}}',
    seePreviousLives: 'Ver lives anteriores',
    livesTitle: 'Lives',
    livesSubtitle: 'Veja as lives que ainda vão acontecer e as que já rolaram',
    othersLivesTitle: 'Outras lives',
    remembered: 'Lembrado',
    remember: 'Lembrar',
    viewLive: 'Ver live',
    certified: 'Certificado',
    progressbarTitle: 'Progresso do curso',
    downloadTooltip: 'Complete {{percent}} do curso para disponibilizar o seu certificado.',
    expiresLabel: 'Termina em:',
    tabGeneral: 'Visão Geral',
    errorRecentlySaved: 'Ocorreu um erro ao tentar carregas os últimos favoritos. Tente novamente mais tarde.',
    tabContent: {
      title: 'Conteúdo',
      cardModuleAction: 'Ver aulas',
      continue: {
        whereStopped: 'Onde você parou',
        nextDiscipline: 'Próxima disciplina',
        error: {
          defaultMessage: 'Ocorreu um erro ao tentar trazer o último conteúdo acessado.'
        }
      },
      professors: {
        title: 'Professores',
        subtitle: 'Conheça os especialistas que vão te ajudar no seu aprendizado',
        buttonLoadMore: 'Carregar mais'
      },
      discipline: {
        title: {
          key: 'disciplina',
          key_plural: 'disciplinas',
          keyWithCount: '{{count}} disciplina',
          keyWithCount_plural: '{{count}} disciplinas'
        },
        discipline: {
          key: 'Disciplina',
          key_plural: 'Disciplinas',
          keyWithCount: '{{count}} disciplina',
          keyWithCount_plural: '{{count}} disciplinas'
        },
        whatCourseHas: 'O que esse curso possui',
        whatCourseHasSubtitle: 'Tudo o que você tem acesso na plataforma',
        status: {
          done: 'Concluídas',
          all: 'Todas',
          incomplete: 'Incompleto'
        },
        error: {
          defaultMessage: 'Ocorreu um erro ao carregar suas disciplinas. Tente novamente mais tarde.'
        }
      }
    },
    tabQuestions: {
      title: 'Perguntas e Respostas',
      interact: {
        button: 'Fazer nova pergunta',
        subtitle: 'Pergunte e ajude seus colegas a tirar suas dúvidas sobre o curso',
        title: 'Interaja com seus colegas'
      },
      search: {
        input: 'Encontre sua pergunta',
        noAnswers: 'Perguntas sem respostas',
        participate: 'Perguntas que participo'
      },
      list: {
        questions: 'Perguntas',
        participate: 'Perguntas que participo',
        orderBy: {
          title: 'Ordenar por',
          best: 'Melhores perguntas',
          first: 'Mais recentes primeiro'
        }
      }
    }
  },
  bookmark: {
    title: 'Favoritos',
    subtitle: 'Encontre aqui todos seus conteúdos favoritos',
    search: 'Pesquisar favoritos',
    failRemoveBookmark: 'Ocorreu um erro ao remover favorito.',
    subHeader: {
      counter: {
        key: 'item favorito',
        key_plural: 'itens favoritos',
        keyWithCount: '{{count}} item favorito',
        keyWithCount_plural: '{{count}} itens favoritos'
      },
      all: 'Todos',
      videos: 'Vídeos',
      documents: 'Materiais',
      questions: 'Questões'
    },
    confirmDelete: {
      title: 'Deseja remover este conteúdo dos seus favoritos?'
    }
  },
  questionBase: {
    hitoricButton: 'Histórico',
    questionsButton: 'BANCO DE QUESTÕES',
    completed: {
      title: 'Resultado',
      subtitle: 'Verifique como você se saiu nessa sessão prática'
    },
    question: {
      title: 'Questões',
      endPracticeButton: 'Encerrar prática',
      corrects: 'Corretas',
      wrong: 'Erradas',
      skipped: 'Puladas',
      saveQuestion: 'Salvar questão',
      seeFilters: 'Ver filtros',
      empty: 'Ops, não foram encontradas questões com os filtros atuais. Clique em "Ver Filtros" e tente novamente utilizando novos',
      goOut: {
        title: 'Ops! Você está saindo da prática.',
        subtitle: 'Deseja realmente encerrar a prática?'
      },
      failHandleBookmark: 'Ocorreu um erro ao favoritar questão.',
      backBookmark: 'Voltar para favoritos',
      bookmark: 'Favorito',
      error: 'Ocorreu um erro ao tentar carregar questões.'
    },
    historic: {
      title: 'Histórico de questões',
      subtitle: 'Confira as últimas questões respondidas por você',
      empty: 'Não há histórico',
      error: 'Ocorreu um erro ao mostrar o histórico de questões.'
    },
    filter: {
      title: 'Banco de questões',
      subtitle: 'Hora de praticar tudo o que você aprendeu no curso',
      startPracticeButton: 'Iniciar prática',
      subject: {
        title: 'Assunto',
        choose: 'Escolher assuntos',
        selecteds: 'Assuntos'
      },
      discipline: {
        title: 'Disciplina',
        choose: 'Escolher disciplina',
        selecteds: 'Disciplinas'
      },
      bank: {
        title: 'Banca',
        choose: 'Escolher bancas',
        selecteds: 'Bancas'
      },
      instituition: {
        title: 'Instituição',
        choose: 'Escolher instituição',
        selecteds: 'Instituições'
      },
      year: {
        name: 'Ano',
        choose: 'Escolher ano',
        selecteds: 'Anos'
      },
      test: {
        title: 'Prova',
        choose: 'Escolher provas',
        selecteds: 'Provas'
      },
      advanced: {
        show: 'Ver filtros avançados',
        hide: 'Ocultar filtros avançados',
        state: {
          label: 'Estado',
          placeholder: 'Escolher o estado'
        },
        year: {
          label: 'Ano',
          placeholder: 'Escolher o ano. (Min: {{min}}, máx: {{max}})'
        },
        difficulty: {
          label: 'Dificuldade',
          options: {
            easy: 'Fácil',
            intermediate: 'Intermediária',
            hard: 'Difícil'
          }
        },
        progress: {
          label: 'Progresso em questões',
          options: {
            all: 'Todas as questões',
            neverAnswered: 'Nunca respondidas',
            alreadyAnswered: 'Já respondidas',
            issuesMissed: 'Questões que errei'
          }
        },
        justCommented: 'Apenas comentadas'
      }
    }
  },
  classroom: {
    rateClass: 'Avaliar aula',
    question: 'Questão',
    questions: 'Questões',
    questionMap: 'Mapa de questões',
    discussions: 'Tire suas Dúvidas',
    favoriteQuestion: 'Favoritar questão',
    favoriteClass: 'Favoritar aula',
    failLoadRating: 'Ocorreu um erro ao carregar a avaliação da aula.',
    correct: 'Corretas',
    wrong: 'Erradas',
    skipped: 'Puladas',
    quizFinalized: 'Quiz finalizado',
    bookmarkDocument: 'Favoritar material',
    next: 'Próxima',
    previous: 'Anterior',
    concluded: 'Concluído',
    askQuestions: 'Fazer Questões',
    viewDiscussions: 'Tire suas Dúvidas',
    failHandleBookmark: 'Ocorreu um erro ao favoritar conteúdo.',
    failLoadClassroom: 'Ocorreu um erro ao carregar sala de aula.',
    failLoadComments: 'Ocorreu um erro ao carregar comentários. Tente novamente mais tarde.',
    failRateClass: 'Ocorreu um erro ao avaliar a aula.',
    failReplyQuestion: 'Ocorreu um erro ao responder a questão.',
    error: 'Ocorreu um erro ao carregar este vídeo.',
    document: {
      error: 'Ocorreu um erro ao carregar este documento.'
    },
    mock: {
      error: 'Ocorreu um erro ao carregar o simulado.',
      errorAnswering: 'Ocorreu um erro ao tentar responder esta questão.'
    },
    classPlaylist: {
      courseError: 'Ocorreu um erro ao carregar os dados deste curso.',
      modulesError: 'Ocorreu um erro ao carregar as disciplinas deste curso.',
      goToBegin: 'Voltar para o início'
    },
    failRemoveComment: 'Ocorreu um erro ao excluir seu comentário.',
    failCreateComment: 'Ocorreu um erro ao criar seu comentário.',
    failLoadRepliesComment: 'Ocorreu um erro ao carregar sub comentários.',
    failInteractionComment: 'Ocorreu um erro ao dar like/dislike no comentário.'
  },
  footer: {
    helpButton: 'Precisa de ajuda?',
    phone: '71 99947-8437',
    email: 'atendimento@sanar.com',
    attendance: 'Atendimento de Segunda a Sexta das 09h às 18h',
    copyright: 'Copyright © Sanar. Todos os direitos reservados.'
  },
  withoutCourse: {
    noEnrollment: 'Você não possui matrícula em nenhum curso.',
    errorMessage: 'O aluno não possui nenhuma matrícula cadastrada',
    expiredOrCanceled: 'Você não possui matrícula ativa em nenhum curso.',
    defaultMessage: 'Você não tem acesso a nenhum curso.',
    goToLogin: 'Ir para o login'
  },
  errors: {
    tryAgain: 'Tentar novamente',
    mainPage: 'Página principal',
    navigateBack: 'Página anterior',
    notFound: {
      title: 'Oops! Não encontramos o que você buscou.',
      subTitle: 'A página que você tentou acessar está indisponível ou não existe.'
    },
    authorization: {
      title: 'Acesso negado/proibido',
      subTitle: 'Desculpe, mas você não tem permissão para acessar essa página.'
    },
    default: {
      title: 'Alguma coisa está errada...',
      subTitle: 'Nosso time já identificou o problema e está trabalhando para resolvê-lo! Por favor, tente novamente em alguns minutos.'
    }
  }
};
exports.default = _default;