"use strict";

exports.__esModule = true;
exports.default = void 0;

var _Typography = _interopRequireDefault(require("./Typography"));

exports.default = _Typography.default;

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}