"use strict";

exports.__esModule = true;
exports.default = void 0;

var _SplashLoader = _interopRequireDefault(require("./SplashLoader"));

exports.default = _SplashLoader.default;

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}