"use strict";

var _defineProperty = require("/vercel/path0/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/defineProperty");

var _slicedToArray = require("/vercel/path0/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/slicedToArray");

exports.__esModule = true;
exports.default = void 0;

var _react = _interopRequireWildcard(require("react"));

var _classnames = _interopRequireDefault(require("classnames"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _reactI18next = require("react-i18next");

var _reactLinesEllipsis = _interopRequireDefault(require("react-lines-ellipsis"));

var _responsiveHOC = _interopRequireDefault(require("react-lines-ellipsis/lib/responsiveHOC"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _getRequireWildcardCache() {
  if (typeof WeakMap !== "function") return null;
  var cache = new WeakMap();

  _getRequireWildcardCache = function _getRequireWildcardCache() {
    return cache;
  };

  return cache;
}

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  }

  if (obj === null || typeof obj !== "object" && typeof obj !== "function") {
    return {
      default: obj
    };
  }

  var cache = _getRequireWildcardCache();

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

  for (var key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }

  newObj.default = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

var ResponsiveEllipsis = (0, _responsiveHOC.default)()(_reactLinesEllipsis.default);

var SeeMoreOrLess = function SeeMoreOrLess(_ref2) {
  var onClick = _ref2.onClick,
      indicator = _ref2.indicator,
      ellipsed = _ref2.ellipsed;

  var _ref3 = (0, _reactI18next.useTranslation)('sanarui'),
      t = _ref3.t;

  return _react.default.createElement("span", {
    onClick: onClick
  }, indicator || _react.default.createElement("span", {
    className: "es-typography--ellipsis-action"
  }, ellipsed ? "... ".concat(t('typography.seeMore')) : t('typography.seeLess')));
};

var ESTypography = function ESTypography(_ref) {
  var _ref6;

  var className = _ref.className,
      type = _ref.type,
      level = _ref.level,
      variant = _ref.variant,
      regular = _ref.regular,
      transform = _ref.transform,
      disabled = _ref.disabled,
      strong = _ref.strong,
      ellipsis = _ref.ellipsis,
      children = _ref.children,
      component = _ref.component,
      deleteProp = _ref.delete,
      props = _objectWithoutProperties(_ref, ["className", "type", "level", "variant", "regular", "transform", "disabled", "strong", "ellipsis", "children", "component", "delete"]);

  var _ref4 = (0, _react.useState)(ellipsis && typeof ellipsis === 'object'),
      _ref5 = _slicedToArray(_ref4, 2),
      ellipsed = _ref5[0],
      setEllipsed = _ref5[1];

  var classes = (0, _classnames.default)('es-typography', className, (_ref6 = {}, _defineProperty(_ref6, "es-typography--".concat(type), type), _defineProperty(_ref6, "es-typography--".concat(variant), variant), _defineProperty(_ref6, 'es-typography--strong', strong), _defineProperty(_ref6, 'es-typography--regular', regular), _defineProperty(_ref6, 'es-typography--disabled', disabled), _defineProperty(_ref6, 'es-typography--delete', deleteProp), _defineProperty(_ref6, 'es-typography--ellipsis', ellipsis && typeof ellipsis !== 'object'), _defineProperty(_ref6, "es-typography--transform-".concat(transform), transform), _defineProperty(_ref6, "es-typography__heading es-typography__heading--h".concat(level), level && level !== ''), _ref6));

  var configureEllipsed = function configureEllipsed() {
    setEllipsed(function (old) {
      return !old;
    });
  };

  if (typeof ellipsis === 'object' && ellipsed) {
    return ellipsis.fakeClamp ? _react.default.createElement("div", {
      className: classes
    }, _react.default.createElement("div", {
      style: {
        height: '45px',
        overflow: 'hidden'
      }
    }, children), children.length >= 51 && '...') : _react.default.createElement(ResponsiveEllipsis, _extends({
      style: {
        whiteSpace: 'pre-wrap'
      },
      text: children,
      trimRight: true,
      maxLine: ellipsis.rows ? ellipsis.rows : 2,
      basedOn: ellipsis.basedOn,
      ellipsis: ellipsis.showAction && _react.default.createElement(SeeMoreOrLess, {
        onClick: configureEllipsed,
        indicator: ellipsis.indicatorMore,
        ellipsed: true
      }),
      className: classes,
      "data-testid": "es-typography"
    }, props));
  }

  var Component = component ? component : 'div';
  return _react.default.createElement(Component, _extends({
    className: classes,
    "data-testid": "es-typography"
  }, props), children, typeof ellipsis === 'object' && ellipsis.showAction && _react.default.createElement("div", null, _react.default.createElement(SeeMoreOrLess, {
    onClick: configureEllipsed,
    indicator: ellipsis.indicatorLess,
    ellipsed: false
  })));
};

ESTypography.propTypes = {
  className: _propTypes.default.string,
  component: _propTypes.default.string,
  level: _propTypes.default.oneOf([1, 2, 3, 4, 5, 6]),
  variant: _propTypes.default.oneOf(['subtitle1', 'subtitle2', 'body1', 'body2', 'caption', 'overline']),
  transform: _propTypes.default.oneOf(['initial', 'uppercase']),
  regular: _propTypes.default.bool,
  delete: _propTypes.default.bool,
  disabled: _propTypes.default.bool,
  ellipsis: _propTypes.default.oneOfType([_propTypes.default.bool, _propTypes.default.shape({
    rows: _propTypes.default.number,
    indicatorMore: _propTypes.default.oneOfType([_propTypes.default.bool, _propTypes.default.node]),
    indicatorLess: _propTypes.default.oneOfType([_propTypes.default.bool, _propTypes.default.node]),
    showAction: _propTypes.default.bool,
    basedOn: _propTypes.default.oneOf(['letters', 'words'])
  })]),
  onChange: _propTypes.default.func,
  type: _propTypes.default.oneOf(['info', 'warning', 'danger', 'success', 'muted', 'default', 'light', 'secondary'])
};
ESTypography.defaultProps = {};
var _default = ESTypography;
exports.default = _default;