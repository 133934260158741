"use strict";

exports.__esModule = true;
exports.default = void 0;

var _Icon = _interopRequireDefault(require("./Icon"));

exports.default = _Icon.default;

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}